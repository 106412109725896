.user-show {
  position: absolute;
  top: 5%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.user-show h3 {
  margin-bottom: 6px;
}

.sellGardenBtn {
  position: absolute;
  top: 5%;
  left: 10%;
}
