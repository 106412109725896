body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
::after,
::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
:root {
  --green: #6fcf97;
  --white: #f5f5f5;
}
body {
  background-color: var(--green);
  color: var(--white);
  font-family: "Quicksand", sans-serif;
}
.flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.equaly-space {
  display: grid;
  gap: 1em;
  justify-items: center;
}
.gap-05 {
  gap: 0.8em;
}
.gap-12 {
  gap: 1.6em;
}

.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}
.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
}
.logo {
  max-width: 75px;
  width: calc(8vw + 2rem);
}
.w-6 {
  max-width: 648px;
}
.w-full {
  width: 100%;
}
input {
  font-size: 1rem;
  font-weight: 400;
  background: transparent;
  padding: 6px;
  border: 2px solid white;
  border-radius: 22px;
  color: #f5f5f5;
  max-width: 520px;
  width: 100%;
  font-family: "Quicksand", sans-serif;
}

input:focus,
:active {
  outline: none;
}
label {
  font-weight: 700;
}
button {
  padding: 6px 12px;
  color: var(--green);
  background-color: var(--white);
  font-size: 1.1rem;
  font-weight: 700;
  border: 2px solid white;
  border-radius: 22px;
  cursor: pointer;
  transition: all 150ms ease-out;
  text-transform: capitalize;
}
button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}
.outline {
  background: transparent;
  color: var(--white);
  border: none;
}
.outline:hover {
  color: var(--green);
  background-color: var(--white);
}

.quantity-wrapper {
  display: flex;
  justify-content: space-between;
}
.quantity-wrapper button {
  width: 34px;
}
.quantity-wrapper span {
  width: 80px;
  font-weight: 700;
  font-size: 1.2rem;
}

p {
  font-weight: bold;
  text-align: center;
}

.text-red {
  color: rgb(245, 66, 66);
  text-align: center;
}
.border {
  border: 1px solid;
}
@media screen and (max-width: 480px) {
  body {
    font-size: 12px;
  }
  .user-show {
    position: absolute;
    top: 6%;
    left: 6%;
  }
}
